import { EuiButtonIcon, EuiSpacer, useEuiTheme } from '@elastic/eui'
import { ACTION } from '@shared/config'
import { useEffect, useRef, useState } from 'react'

import { type SaveButtonProps } from './types'

const SaveButton = ({ isActive, onClick, isLoading }: SaveButtonProps) => {
  const { euiTheme } = useEuiTheme()
  const [isSticky, setIsSticky] = useState(false)
  const [stickyOffsetLeft, setStickyOffsetLeft] = useState(0)
  const elementRef = useRef<HTMLButtonElement>(null)
  const placeholderRef = useRef<HTMLDivElement>(null)

  const updateStickyState = () => {
    if (!elementRef.current || !placeholderRef.current) return

    const elementTop = placeholderRef.current.getBoundingClientRect().top
    const shouldBeSticky = elementTop <= euiTheme.base

    setIsSticky(shouldBeSticky)

    if (shouldBeSticky) {
      setStickyOffsetLeft(elementRef.current.getBoundingClientRect().left)
    }
  }

  useEffect(() => {
    if (isActive) {
      updateStickyState()
      window.addEventListener('scroll', updateStickyState)
    }

    return () => {
      window.removeEventListener('scroll', updateStickyState)
    }
  }, [isActive])

  if (!isActive) {
    return <EuiSpacer size="xl" />
  }

  return (
    <>
      {/* Плейсхолдер для сохранения места элемента при его фиксации */}
      <div
        ref={placeholderRef}
        style={{ height: isSticky ? elementRef.current?.offsetHeight : 0 }}
      />

      <EuiButtonIcon
        iconType="save"
        size="s"
        color="primary"
        display="fill"
        aria-label={ACTION.SAVE}
        onClick={onClick}
        isLoading={isLoading}
        buttonRef={elementRef}
        style={{
          position: isSticky ? 'fixed' : 'relative',
          top: isSticky ? euiTheme.size.base : 'auto',
          left: isSticky ? `${stickyOffsetLeft}px` : 'auto',
          zIndex: 999,
        }}
      />
    </>
  )
}

export { SaveButton }
