import { EuiFormRow, EuiSuperSelect } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { Controller, useFormContext } from 'react-hook-form'

import { type SelectInputProps } from './types'

const SelectInput = <T,>({
  label = '',
  name,
  placeholder = '',
  inputProps,
  registerOptions,
}: SelectInputProps<T>) => {
  const {
    formState: { errors },
  } = useFormContext()

  const error = errors?.[name]

  return (
    <EuiFormRow label={label} isInvalid={!!error} error={isFieldError(error) && error.message}>
      <Controller
        name={name}
        rules={registerOptions}
        render={({ field: { value, onChange } }) => {
          return (
            <EuiSuperSelect<T>
              placeholder={placeholder}
              isInvalid={!!error}
              name={name}
              valueOfSelected={value}
              onChange={onChange}
              options={inputProps?.options || []}
              {...inputProps}
            />
          )
        }}
        options={inputProps?.options || []}
        {...inputProps}
      />
    </EuiFormRow>
  )
}

export { SelectInput }
