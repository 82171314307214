import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiValidator } from '@shared/api/types'
import { ATTRIBUTE_FIELD_NAMES, LABEL } from '@shared/config'
import { getDateColumnRender } from '@shared/lib'

import { renderValidatorName } from '../lib'

export const VALIDATORS_COLUMNS: EuiBasicTableColumn<ApiValidator>[] = [
  {
    field: 'id',
    name: LABEL.ID,
    width: '120px',
  },
  {
    field: 'validated_attribute_name',
    name: LABEL.ATTRIBUTE,
    render: renderValidatorName,
  },
  {
    field: 'created_at',
    name: ATTRIBUTE_FIELD_NAMES.created_at,
    render: getDateColumnRender<ApiValidator, 'created_at'>(),
  },
  {
    field: 'updated_at',
    name: ATTRIBUTE_FIELD_NAMES.updated_at,
    render: getDateColumnRender<ApiValidator, 'updated_at'>(),
  },
]
