import { useCategoryApi, useUpdateCategoryApi } from '@entities/categories'

const useRemoveCategory = (id: number, onSuccessCallback?: EmptyCallback) => {
  const categoryQuery = useCategoryApi(id)

  const isDisabledButton =
    !categoryQuery.data ||
    !!categoryQuery.data?.attributes_count ||
    !!categoryQuery.data?.skus_count

  const onSuccess = () => {
    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const remove = useUpdateCategoryApi()

  const removeCategory = () => {
    remove.mutate(
      {
        params: {
          path: {
            id,
          },
        },
        body: {
          is_deleted: true,
        },
      },
      { onSuccess },
    )
  }

  return {
    isDisabledButton,
    isLoading: categoryQuery.isLoading,
    isSubmitting: remove.isPending,
    removeCategory,
  }
}

export { useRemoveCategory }
