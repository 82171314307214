import { type EuiBasicTableColumn } from '@elastic/eui'

import { renderUserName } from '../lib'
import { type UsersTableRow } from '../types'

export const USERS_COLUMNS: EuiBasicTableColumn<UsersTableRow>[] = [
  {
    field: 'name',
    name: 'Имя',
    render: renderUserName,
  },
  {
    field: 'surname',
    name: 'Фамилия',
  },
  {
    field: 'email',
    name: 'Email',
  },
]
