import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAddUser } from '@shared/api/types'

import { useAddUserFieldValueApi, useUpdateUserFieldValueApi } from '../api'
import { type HandleUpdateFieldValueArgs } from './types'

const useUpdateUserFieldValue = (userId: number, onSuccessCallback?: EmptyCallback) => {
  const { mutate: updateUserFieldValue, isPending: isUserFieldValueUpdating } =
    useUpdateUserFieldValueApi()

  const { mutate: addUserFieldValue, isPending: isUserFieldValueAdding } = useAddUserFieldValueApi()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.USER(userId) })

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleUpdateFieldValue = ({
    userFieldValueData,
    fieldValueId,
  }: HandleUpdateFieldValueArgs) => {
    return updateUserFieldValue(
      {
        params: {
          path: {
            id: fieldValueId,
          },
        },
        body: userFieldValueData,
      },
      { onSuccess },
    )
  }

  const handleAddFieldValue = (userFieldValueData: ApiAddUser) => {
    return addUserFieldValue({ body: userFieldValueData }, { onSuccess })
  }

  return {
    handleUpdateFieldValue,
    handleAddFieldValue,
    isSubmitting: isUserFieldValueUpdating || isUserFieldValueAdding,
  }
}

export { useUpdateUserFieldValue }
