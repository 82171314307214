import { addSkuApi } from '@entities/catalog'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAddSku } from '@shared/api/types'
import { ERROR } from '@shared/config'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { type AddSkuFormInputs } from './types'

const useAddSku = (onClose: EmptyCallback) => {
  const methods = useForm<AddSkuFormInputs>()

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const { mutate, isPending } = useMutation({
    mutationFn: async (fieldsData: ApiAddSku) => {
      return await addSkuApi(fieldsData)
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: ERROR.SERVER,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.SKU_LIST })
      handleModalClose()
    },
  })

  const handleSubmit = methods.handleSubmit((fieldsData) => {
    mutate(fieldsData)
  })

  return {
    handleModalClose,
    methods,
    handleSubmit,
    isSubmitting: isPending,
  }
}

export { useAddSku }
