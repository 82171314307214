import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { useUserPermissions } from '@entities/user'
import { AddAttribute, LinkAttributeToCategory } from '@features/attributes'
import { PERMISSIONS } from '@shared/api'
import { ACTION } from '@shared/config'
import { useDialog, useRouterParams } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'

const AttributeActions: ReactFc = () => {
  const { getParamValues } = useRouterParams()

  const attributeIds = getParamValues('selected')

  const {
    isOpen: isAddAttributeModalOpen,
    handleOpenDialog: showAddAttributeModal,
    handleCloseDialog: closeAddAttributeModal,
  } = useDialog()

  const {
    isOpen: isLinkToCategoryModalOpen,
    handleOpenDialog: showLinkToCategoryModal,
    handleCloseDialog: closeLinkToCategoryModal,
  } = useDialog()

  const { permissions } = useUserPermissions()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        ...(permissions[PERMISSIONS.CAN_ADD_ATTRIBUTES]
          ? [
              {
                name: ACTION.NEW_ATTRIBUTE,
                icon: 'plus',
                onClick: showAddAttributeModal,
              },
            ]
          : []),
        {
          name: ACTION.LINK_TO_CATEGORY,
          icon: 'symlink',
          onClick: showLinkToCategoryModal,
          disabled: attributeIds.length === 0,
        },
        // TODO: раскоментировать, когда будет готов функционал
        // {
        //   name: 'Пересчитать прогресс товаров',
        //   icon: 'refresh',
        // },
        // {
        //   name: 'Создать отчет по атрибутам',
        //   icon: 'visPie',
        // },
        // {
        //   name: 'Создать отчет по ошибкам',
        //   icon: 'visLine',
        // },
      ],
    },
  ]

  return (
    <>
      <ContextMenu menuButtonText="Действия" panels={panels} endIcon="arrowDown" />
      <AddAttribute isOpen={isAddAttributeModalOpen} onClose={closeAddAttributeModal} />
      <LinkAttributeToCategory
        isOpen={isLinkToCategoryModalOpen}
        onClose={closeLinkToCategoryModal}
      />
    </>
  )
}

export { AttributeActions }
