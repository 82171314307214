import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'

import { useCategoryApi } from '../api'

const CategoriesParent: ReactFc<ItemWithId> = ({ id }) => {
  const { data: parentCategory } = useCategoryApi(id)

  const name = extractAttribute(parentCategory, ATTRIBUTE_FLAGS.NAME)?.value

  return <>{name}</>
}

export { CategoriesParent }
