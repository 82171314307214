import { EuiForm, EuiHeaderLink } from '@elastic/eui'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, NumberInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddAttributeGroup } from '../model'

export const AddAttributeGroup: ReactFc = () => {
  const { isOpen, showModal, closeModal, methods, addGroup, isSubmitting } = useAddAttributeGroup()

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {ACTION.ADD_GROUP}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={ACTION.ADD_ATTRIBUTE_GROUP}
          onClose={closeModal}
          onSubmit={addGroup}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder={LABEL.ATTRIBUTE_GROUP_NAME}
              registerOptions={{ required: MESSAGE.ENTER_NAME }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />
            <NumberInput
              name="order"
              placeholder={LABEL.ORDER}
              inputProps={{
                disabled: isSubmitting,
              }}
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}
