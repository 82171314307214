import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { Catalog, CatalogHeader, CatalogSidebar } from '@widgets/catalog'

export const CatalogPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SKUS.CATALOG.title })

  return (
    <Layout header={<CatalogHeader />} sidebar={<CatalogSidebar />}>
      <Catalog />
    </Layout>
  )
}
