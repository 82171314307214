import { addAttributesList, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { LABEL } from '@shared/config'
import { useParams } from 'react-router-dom'

const useFeaturedGroupsList = () => {
  const { id: skuId } = useParams()
  const skuQuery = useSkuApi(skuId)
  const attributeGroupsQuery = useAttributeGroupsList()

  // Добавляем прогресс бар и ставим на первую позицию
  const progressGroup = {
    id: 0,
    name: LABEL.FULLNESS,
    order: 0,
    attributes: [
      {
        id: 0,
        name: LABEL.PROGRESS,
        value: skuQuery.data?.progress.toString(),
        slug: 'progress',
      },
    ],
  } as AttributeGroup

  if (attributeGroupsQuery.isSuccess && skuQuery.isSuccess) {
    let attributesGroupsList = addAttributesList({
      groups: attributeGroupsQuery.data,
      attributes: skuQuery.data.attributes.filter((attribute) => {
        return attribute.is_featured === true
      }),
    }).filter((group) => {
      return group.attributes.length > 0
    })

    attributesGroupsList = attributesGroupsList.concat(progressGroup)

    return attributesGroupsList as AttributeGroup[]
  }

  return []
}

export { useFeaturedGroupsList }
