import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { CategorizationHeader, CategorizationTable } from '@widgets/settings'

export const CategorizationPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.CATEGORIZATION.title })

  return (
    <Layout header={<CategorizationHeader />}>
      <CategorizationTable />
    </Layout>
  )
}
