import { type ApiValidator } from '@shared/api/types'
import { ROUTES } from '@shared/config'
import { Link } from 'react-router-dom'

const renderValidatorName: RenderColumn<ApiValidator, 'validated_attribute_name'> = (
  name,
  { id },
) => {
  return <Link to={ROUTES.SETTINGS.VALIDATORS.VALIDATOR.buildPath({ id })}>{name}</Link>
}

export { renderValidatorName }
