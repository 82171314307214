import { useCatalogListApi } from '@entities/catalog'
import { useCategoriesList } from '@entities/categories'
import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { convertToOption } from '@shared/lib'

const useSkuSelectOptions = () => {
  const { getParamValues } = useRouterParams()

  // Список каталогов
  const { isLoading: isCatalogOptionsLoading, data: catalogs = [] } = useCatalogListApi()
  const catalogOptions = catalogs.map(convertToOption)

  // Список категорий
  const { isLoading: isCategoryOptionsLoading, data: categories = [] } = useCategoriesList()
  const categoryOptions = categories.map(convertToOption)

  // Выбранный каталог
  const catalogSelected = getParamValues(PARAMS.OBJECT_GROUPS)
  const catalogDefaultOption = parseInt(catalogSelected[0]) || undefined

  // Выбранная категория
  const categorySelected = getParamValues(PARAMS.CATEGORIES)
  const categoryDefaultOption = parseInt(categorySelected[0]) || undefined

  return {
    catalogDefaultOption,
    categoryDefaultOption,
    isCatalogOptionsLoading,
    catalogOptions,
    isCategoryOptionsLoading,
    categoryOptions,
  }
}

export { useSkuSelectOptions }
