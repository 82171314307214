import { type CategoryTreeItem } from '@entities/categories/types'

export const createSearchFilterTree = (
  categories: CategoryTreeItem[],
  search: string,
): CategoryTreeItem[] => {
  // Глубокое копирование для сохранения сворачивания категорий
  const categoriesCopy = structuredClone(categories)

  const resultIds = categoriesCopy
    .filter((category) => {
      return category.name.toLowerCase().includes(search)
    })
    .map((category) => {
      return category.id
    })

  // Раскрываем древо до нужного элемента
  const expandCategoriesIfChildIncluded = (categories: CategoryTreeItem[], resultIds: number[]) => {
    categories.forEach((category) => {
      category.isExpanded = category.childNodes.some((childId) => {
        return resultIds.includes(childId)
      })
    })
  }

  expandCategoriesIfChildIncluded(categoriesCopy, resultIds)

  // Отфильтровываем результаты и все раскрытые категории до результата
  const filterResultCategories = (
    categories: CategoryTreeItem[],
    resultIds: number[],
  ): CategoryTreeItem[] => {
    return categories.filter((category) => {
      return resultIds.includes(category.id) || category.isExpanded
    })
  }

  return filterResultCategories(categoriesCopy, resultIds)
}
