import { EuiHeaderLink } from '@elastic/eui'
import { ACTION } from '@shared/config'

import { useRemoveAttributeFromCategory } from '../model'

export const RemoveAttributeFromCategory: ReactFc = () => {
  const { isDisabledButton, isPending, removeFromCategory } = useRemoveAttributeFromCategory()

  return (
    <EuiHeaderLink
      iconType="minusInCircle"
      color="primary"
      iconSide="right"
      onClick={removeFromCategory}
      isLoading={isPending}
      disabled={isDisabledButton}
    >
      {ACTION.EXCLUDE_SELECTED}
    </EuiHeaderLink>
  )
}
