import { EuiSelect } from '@elastic/eui'
import { useCatalogListApi } from '@entities/catalog'
import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { createCatalogSelectOptions } from '../model'

export const CatalogSelect: ReactFc = () => {
  const [searchParams] = useSearchParams()
  const { hasParam, setParam, deleteParam } = useRouterParams()
  const { isLoading, data: catalogs = [], hasCatalogs } = useCatalogListApi()

  // Если на странице каталога нет параметра id ставим первый каталог
  useEffect(() => {
    if (!hasParam(PARAMS.OBJECT_GROUPS) && hasCatalogs) {
      setParam(PARAMS.OBJECT_GROUPS, catalogs[0].id)
    }
  }, [hasParam(PARAMS.OBJECT_GROUPS), hasCatalogs])

  const catalogId = searchParams.get(PARAMS.OBJECT_GROUPS) ?? undefined
  const catalogsOptions = createCatalogSelectOptions({ catalogs, isLoading, hasCatalogs })

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setParam(PARAMS.OBJECT_GROUPS, event.target.value)
    deleteParam(PARAMS.CATEGORIES)
  }

  return (
    <EuiSelect
      isLoading={isLoading}
      value={catalogId}
      options={catalogsOptions}
      onChange={handleSelect}
      disabled={!hasCatalogs}
    />
  )
}
