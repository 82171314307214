import { type EuiBasicTableColumn } from '@elastic/eui'
import { LABEL } from '@shared/config'

import { renderSkuName } from '../lib'
import { type Sku } from '../types'

const SETTINGS_SKUS_COLUMNS: EuiBasicTableColumn<Sku>[] = [
  {
    field: 'name',
    name: LABEL.SKU_NAME,
    render: renderSkuName,
  },
]

export { SETTINGS_SKUS_COLUMNS }
