import { EuiIcon } from '@elastic/eui'
import { useUserPermissions } from '@entities/user'
import { PERMISSIONS } from '@shared/api'
import { LABEL, PAGE_TITLES, ROUTES } from '@shared/config'

import { createItem } from './create-item'

const useSidebarMenuItems = () => {
  const { permissions } = useUserPermissions()

  return [
    {
      id: 'menu',
      name: LABEL.MENU.toUpperCase(),
      items: [
        {
          id: 'all',
          name: PAGE_TITLES.SKUS.title,
          icon: <EuiIcon type="package" />,
          forceOpen: true,
          items: [
            createItem(
              PAGE_TITLES.SKUS.CATALOG.title,
              ROUTES.CATALOG.path,
              permissions[PERMISSIONS.CAN_VIEW_CATALOG],
            ),
          ],
        },
        {
          id: 'settings',
          name: PAGE_TITLES.SETTINGS.title,
          icon: <EuiIcon type="gear" />,
          forceOpen: true,
          items: [
            createItem(
              PAGE_TITLES.SETTINGS.ATTRIBUTES.title,
              ROUTES.SETTINGS.ATTRIBUTES.path,
              permissions[PERMISSIONS.CAN_VIEW_ATTRIBUTES],
            ),
            createItem(
              PAGE_TITLES.SETTINGS.ATTRIBUTE_GROUPS.title,
              ROUTES.SETTINGS.ATTRIBUTE_GROUPS.path,
              permissions[PERMISSIONS.CAN_VIEW_OBJECT_GROUPS],
            ),
            createItem(
              PAGE_TITLES.SETTINGS.VALIDATORS.title,
              ROUTES.SETTINGS.VALIDATORS.path,
              // TODO: Добавить права
              // permissions[PERMISSIONS.CAN_VIEW_VALIDATORS],
              true,
            ),
            createItem(
              PAGE_TITLES.SETTINGS.CATEGORIZATION.title,
              ROUTES.SETTINGS.CATEGORIZATION.path,
              true,
            ),
            createItem(
              PAGE_TITLES.SETTINGS.USERS.title,
              ROUTES.SETTINGS.USERS.path,
              // TODO: Добавить права
              // permissions[PERMISSIONS.CAN_VIEW_USERS],
              true,
            ),
          ],
        },
        {
          id: 'export',
          name: PAGE_TITLES.EXPORT.title,
          icon: <EuiIcon type="exportAction" />,
          forceOpen: true,
          items: [createItem(PAGE_TITLES.EXPORT.FILES.title, ROUTES.EXPORT.FILES.path, true)],
        },
      ],
    },
  ]
}

export { useSidebarMenuItems }
