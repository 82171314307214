import { useCatalogListApi } from '@entities/catalog'
import { useCategoriesList, useCategoryApi } from '@entities/categories'
import { convertToComboOption, convertToOption } from '@shared/lib'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useUpdateCategory } from './use-update-category'

const useInfoCategory = () => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)
  const { data: category, isLoading: isCategoryLoading } = useCategoryApi(categoryId)

  const methods = useForm()

  // Список каталогов
  const { isLoading: isCatalogOptionsLoading, data: catalogs = [] } = useCatalogListApi()
  const catalogOptions = catalogs.map(convertToComboOption)

  // Список категорий
  const { isLoading: isCategoryOptionsLoading, data: categories = [] } = useCategoriesList()

  const categoryOptions = categories
    .filter((selectCategory) => {
      return selectCategory.id !== category?.id
    })
    .map(convertToOption)

  const isDataLoading = isCategoryLoading || isCatalogOptionsLoading

  useEffect(() => {
    if (!isDataLoading && category && catalogs) {
      const selectedCatalogOptions = category?.object_group?.map((catalog) => {
        return catalogOptions?.find((catalogOption) => {
          return catalogOption.value === catalog
        })
      })

      const defaultValues = category.attributes.reduce(
        (acc, attribute) => {
          acc[attribute.id] = attribute.value || ''

          return acc
        },
        {
          ...category,
          object_group: selectedCatalogOptions,
        } as Record<number, string>,
      )

      // Обновляем значения формы
      methods.reset(defaultValues)
    }
  }, [isDataLoading, category, catalogs])

  // Отслеживаем изменения всех полей формы
  const watchedFields = methods.watch()

  const changed = Object.keys(watchedFields).filter((field) => {
    return methods.formState.dirtyFields[field]
  })

  // Обновляем категорию
  const { updateCategoryHandler, isSubmitting } = useUpdateCategory({ changed, watchedFields })

  return {
    isCatalogOptionsLoading,
    catalogOptions,
    isCategoryOptionsLoading,
    categoryOptions,
    methods,
    changed,
    handleSubmit: methods.handleSubmit(updateCategoryHandler),
    isSubmitting,
  }
}

export { useInfoCategory }
