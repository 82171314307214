import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import {
  AddAttributeToGroup,
  RemoveAttributeFromGroup,
  UpdateAttributeGroup,
} from '@features/attributes'
import { ACTION } from '@shared/config'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'
import { useParams } from 'react-router-dom'

export const AttributeGroupHeader: ReactFc = () => {
  const { id: attributeGroupIdParam = '' } = useParams()
  const attributeGroupId = parseInt(attributeGroupIdParam)

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={ACTION.FIND_ATTRIBUTE} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSectionItem>
        <RemoveAttributeFromGroup />
        <AddAttributeToGroup />
        <UpdateAttributeGroup id={attributeGroupId} isHeader />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
