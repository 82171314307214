import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { AddUser } from '@features/users'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'

const UsersActions: ReactFc = () => {
  const {
    isOpen: isAddUserModalOpen,
    handleOpenDialog: showAddUserModal,
    handleCloseDialog: closeAddUserModal,
  } = useDialog()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: ACTION.NEW_USER,
          icon: 'plus',
          onClick: showAddUserModal,
        },
      ],
    },
  ]

  return (
    <>
      <ContextMenu menuButtonText={ACTION.ACTIONS} panels={panels} endIcon="arrowDown" />
      <AddUser isOpen={isAddUserModalOpen} onClose={closeAddUserModal} />
    </>
  )
}

export { UsersActions }
