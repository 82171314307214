import { useCategoriesList } from '@entities/categories'
import { LABEL } from '@shared/config'
import { convertToOption } from '@shared/lib'

const useCategoryOptions = () => {
  const { data: categories = [], isLoading } = useCategoriesList()

  const categoryOptions = categories.map(convertToOption)

  categoryOptions.unshift({ value: 0, inputDisplay: LABEL.ATTRIBUTES_ROOT_CATEGORY })

  return { categoryOptions, isLoading }
}

export { useCategoryOptions }
