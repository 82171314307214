import { EuiPageSection } from '@elastic/eui'
import { CategoryInfo } from '@features/categories'

const CategoryInfoTab: ReactFc = () => {
  return (
    <EuiPageSection paddingSize="none">
      <CategoryInfo />
    </EuiPageSection>
  )
}

export { CategoryInfoTab }
