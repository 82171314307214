import { API_QUERY_KEY, queryClient } from '@shared/api'
import { useParams } from 'react-router-dom'

import { useRemoveAttributeValue, useUpdateAttributeValue } from '../api'
import { type UpdateAttributeFilesArgs } from './types'

const useUpdateAttributeFiles = () => {
  const { id: objectIdParam = '' } = useParams()
  const objectId = parseInt(objectIdParam)

  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: API_QUERY_KEY.SKU(objectId),
    })
  }

  const removeFile = useUpdateAttributeValue()
  const removeAttributeValue = useRemoveAttributeValue()

  const updateAttributeFiles = ({ attribute, file: fileForRemove }: UpdateAttributeFilesArgs) => {
    return () => {
      const updatedFiles = attribute.files
        .filter((file) => {
          return file.id !== fileForRemove?.id
        })
        .map((file) => {
          return file.id
        })

      if (updatedFiles.length) {
        removeFile.mutate(
          {
            params: {
              path: {
                id: attribute.value_id,
              },
            },
            body: {
              files: updatedFiles,
            },
          },
          { onSuccess },
        )
      } else {
        removeAttributeValue.mutate(
          {
            params: {
              path: {
                id: attribute.value_id,
              },
            },
          },
          { onSuccess },
        )
      }
    }
  }

  return {
    updateAttributeFiles,
    isSubmitting: removeFile.isPending || removeAttributeValue.isPending,
    onSuccess,
  }
}

export { useUpdateAttributeFiles }
