import { LABEL } from '@shared/config'

import { CategoryAttributesTab } from './category-attributes-tab'
import { CategoryInfoTab } from './category-info-tab'
import { CategorySkusTab } from './category-skus-tab'

const CATEGORY_TABS_LIST = [
  {
    id: 'settings',
    name: LABEL.SETTINGS,
    content: <CategoryInfoTab />,
  },
  {
    id: 'attributes',
    name: LABEL.ATTRIBUTES,
    content: <CategoryAttributesTab />,
  },
  {
    id: 'imagesAndFiles',
    name: LABEL.SKUS,
    content: <CategorySkusTab />,
  },
]

export { CATEGORY_TABS_LIST }
