import {
  type AttributeFieldNames,
  type CategoryFieldNames,
  type ValidatorFieldNames,
} from './types'

export const APP_NAME = 'SKU HUB'
// TODO: Ссылка на публичный каталог, скорее всего будет в ENV
export const APP_PUBLIC_URL = 'https://google.com/search?q='

export const SIMPLE_LAYOUT_CONTENT_WIDTH = 268
export const SIMPLE_LAYOUT_LOGO_WIDTH = 210

export const SIDEBAR_OPEN_MIN_WIDTH = 200
export const SIDEBAR_CLOSED_MIN_WIDTH = 59
export const SIDEBAR_OPEN_LOGO_WIDTH = 118
export const SIDEBAR_CLOSED_LOGO_WIDTH = 21

export const ELEMENTS_XS_WIDTH = 240
export const ELEMENTS_S_WIDTH = 300
export const ELEMENTS_M_WIDTH = 400

export const CATALOG_CARD_SKELETON_AMOUNT = 9
export const CATALOG_CARD_IMAGE_SIZE = 200
export const CATALOG_TABLE_IMAGE_SIZE = 50

export enum CATALOG_VIEW {
  CARDS = 'Сетка',
  TABLE = 'Таблица',
}

export enum LABEL {
  ATTRIBUTE = 'Атрибут',
  ATTRIBUTE_GROUP = 'Группа атрибута',
  ATTRIBUTE_GROUP_NAME = 'Название группы',
  ATTRIBUTE_NAME = 'Название атрибута',
  ATTRIBUTE_TYPE = 'Тип атрибута',
  ATTRIBUTES = 'Атрибуты',
  ATTRIBUTES_IN_CATEGORY = 'Атрибутов в категории',
  ATTRIBUTES_IN_GROUP = 'Атрибутов в группе',
  ATTRIBUTES_ROOT_CATEGORY = 'Корневая категория',
  CATALOG = 'Каталог',
  CATEGORY = 'Категория',
  CATEGORIES = 'Категории',
  CATEGORY_NAME = 'Название категории',
  FULLNESS = 'Заполненность',
  ID = 'ID',
  IMAGES_FILES = 'Изображения и файлы',
  MENU = 'Меню',
  NAME = 'Название',
  ORDER = 'Позиция',
  PARENT_CATEGORY = 'Родительская категория',
  PROGRESS = 'Прогресс',
  RESOLUTION = 'Разрешение',
  SETTINGS = 'Настройки',
  SIZE = 'Размер',
  SKUS = 'Товары',
  SKU_ATTRIBUTES_TYPE_FILTER = 'Тип атрибутов',
  SKU_IN_CATEGORY = 'Товаров в категории',
  SKU_NAME = 'Название товара',
  SKU_SKU = 'Артикул товара',
  USER_NAME = 'Имя пользователя',
  PASSWORD = 'Пароль',
  CONFIRM_PASSWORD = 'Подтверждение пароля',
}

export enum MESSAGE {
  CATEGORY_REQUIRED = 'Не выбрана категория',
  EMPTY = 'Ничего не найдено',
  EMPTY_CATALOGS = 'Каталоги отсутствуют',
  EMPTY_NAME = 'Без названия',
  EMPTY_VALUE = '–',
  ENTER_CATEGORY = 'Выберите категорию',
  ENTER_NAME = 'Введите название',
  ENTER_SKU = 'Введите артикул',
  FILE_UPLOAD = 'Перенесите сюда файл для загрузки',
  FILE_UPLOAD_CREATE_AT = 'Загружен',
  LOADING = 'Загрузка…',
  SELECT_CATALOG = 'Выберите каталог',
}

export enum ERROR {
  ATTRIBUTE_EMPTY = 'Введите значение атрибута',
  NO_PAGE = 'Страница, которую вы ищете, не найдена, попробуйте начать навигацию с главной страницы',
  NOT_EMPTY = 'Нельзя удалить объект, пока в нём содержаться дочерние элементы',
  PERMISSION = 'У вас нет прав для просмотра этого раздела',
  SERVER = 'Ошибка сервера, попробуйте ещё раз',
  ENTER_PASSWORD = 'Введите пароль',
  ENTER_USER_NAME = 'Введите имя',
  USER_NAME_MIN_LENGTH = 'Имя должно содержать минимум 3 символов',
  USER_NAME_MAX_LENGTH = 'Имя не должно превышать 150 символов',
  USER_NAME_FORMAT = 'Имя может содержать только буквы, цифры и символы @._+-',
  USER_PASSWORD_MIN_LENGTH = 'Пароль должен содержать минимум 8 символов',
  USER_PASSWORD_MATCH = 'Пароли не совпадают',
  USER_PASSWORD_NUMERIC = 'Пароль не может состоять только из цифр',
}

export enum ACTION {
  ADD = 'Добавить',
  ADD_ATTRIBUTE_GROUP = 'Создание группы атрибутов',
  ADD_CATEGORY = 'Создать категорию',
  ADD_GROUP = 'Создать группу',
  ALL_FIELDS = 'Все поля',
  ATTRIBUTES_CATEGORY_SELECT = 'Выбрать категорию',
  ATTRIBUTES_LINK = 'Привязать',
  BACK = 'Назад',
  BACK_MAIN = 'Вернуться на главную',
  CREATE = 'Создать',
  DELETE = 'Удалить',
  DELETE_SELECTED = 'Удалить выбранные',
  EDIT = 'Редактировать',
  EDIT_ATTRIBUTE = 'Редактировать атрибут',
  EDIT_ATTRIBUTE_GROUP = 'Редактирование группы атрибутов',
  EDIT_CATEGORY = 'Редактирование категории',
  EXCLUDE_SELECTED = 'Исключить выбранные',
  FIND_ATTRIBUTE = 'Найти атрибут',
  FIND_CATEGORY = 'Найти категорию',
  FIND_GROUP = 'Найти группу',
  FIND_SKU = 'Найти товар',
  FIND_USER = 'Найти пользователя',
  LINK_TO_CATEGORY = 'Привязать к категории',
  NEW_SKU = 'Новый товар',
  NEW_ATTRIBUTE = 'Новый атрибут',
  OBJECT_GROUP_SELECT = 'Выбрать группу',
  SAVE = 'Сохранить',
  SWITCH_USER = 'Сменить профиль',
  TYPE_SELECT = 'Выбрать тип',
  NEW_USER = 'Новый пользователь',
  ACTIONS = 'Действия',
  CHANGE_PASSWORD = 'Изменить пароль',
}

export enum ATTRIBUTE_LABEL {
  BRAND = 'Brand',
  NAME = 'PN',
  PREVIEW = 'Preview',
  SKU = 'SKU',
}

export const ATTRIBUTE_FIELD_NAMES: AttributeFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  name: 'Название',
  attribute_type: 'Тип',
  category: 'Категория',
  is_featured: 'Is featured',
  is_required: 'Обязательный',
  description: 'Описание',
  is_public: 'Публичный',
  object_group: 'Группа',
  is_sku: 'SKU',
  is_name: 'Название продукта',
  is_brand: 'Бренд',
  is_preview: 'Превью',
  slug: 'Slug',
  updated_at: 'Обновлен',
  created_at: 'Создан',
  created_by: 'Создатель',
}

export enum ATTRIBUTE_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  NAME = 'name',
  ATTRIBUTE_TYPE = 'attribute_type',
  CATEGORY = 'category',
  IS_FEATURED = 'is_featured',
  IS_REQUIRED = 'is_required',
  DESCRIPTION = 'description',
  IS_PUBLIC = 'is_public',
  OBJECT_GROUP = 'object_group',
  IS_SKU = 'is_sku',
  IS_NAME = 'is_name',
  IS_BRAND = 'is_brand',
  IS_PREVIEW = 'is_preview',
  SLUG = 'slug',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
}

export const VALIDATOR_FIELD_NAMES: ValidatorFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  use_validators: 'Использовать валидатор',
  validated_attribute_name: 'Валидируемый атрибут',
  is_required_value: 'Обязательное значение',
  block_save_with_validation_errors: 'Блокировать сохранение при ошибке валидации',
  group: 'Группа',
  updated_at: 'Обновлен',
  created_at: 'Создан',
  created_by: 'Создатель',
  weight: 'Вес',
  min_length: 'Минимальная длина',
  max_length: 'Максимальная длина',
  min_numeric_value: 'Минимальное значение',
  max_numeric_value: 'Максимальное значение',
  forbidden_words: 'Запрещенные слова',
  validated_attribute: 'Валидируемый атрибут',
  id: 'ID',
}

export enum VALIDATOR_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  IS_REQUIRED_VALUE = 'is_required_value',
  USE_VALIDATORS = 'use_validators',
  VALIDATORS_ATTRIBUTE_NAME = 'validated_attribute_name',
  WEIGHT = 'weight',
  BLOCK_SAVE_WITH_VALIDATION_ERRORS = 'block_save_with_validation_errors',
  MIN_LENGTH = 'min_length',
  MAX_LENGTH = 'max_length',
  MIN_NUMERIC_VALUE = 'min_numeric_value',
  MAX_NUMERIC_VALUE = 'max_numeric_value',
  FORBIDDEN_WORDS = 'forbidden_words',
  VALIDATED_ATTRIBUTE = 'validated_attribute',
  GROUP = 'group',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
  ID = 'id',
}

export const CATEGORY_FIELD_NAMES: CategoryFieldNames = {
  id: 'ID',
  skus_count: 'Количество SKU',
  attributes_count: 'Количество атрибутов',
  is_editable: 'Редактируемый',
  created_by: 'Создатель',
  uuid: 'UUID',
  created_at: 'Создан',
  updated_at: 'Обновлен',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  group: 'Группа',
  parent: 'Родительская категория',
  object_group: 'Каталоги',
}

export enum CATEGORY_FIELDS {
  ID = 'id',
  SKUS_COUNT = 'skus_count',
  ATTRIBUTES_COUNT = 'attributes_count',
  IS_EDITABLE = 'is_editable',
  CREATED_BY = 'created_by',
  UUID = 'uuid',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  GROUP = 'group',
  PARENT = 'parent',
  OBJECT_GROUP = 'object_group',
}

export enum PARAMS {
  ATTRIBUTE_TYPE = 'attribute_type',
  CATEGORY = 'category',
  CATEGORIES = 'categories',
  CATEGORY_ISNULL = 'category__isnull',
  MODEL = 'model',
  OBJECT_GROUP = 'object_group',
  OBJECT_GROUPS = 'object_groups',
  OBJECT_GROUP_ISNULL = 'object_group__isnull',
  ORDERING = 'ordering',
  PAGE = 'page',
  SEARCH = 'search',
  SIZE = 'size',
}

export enum FILE_UPLOAD_ALLOWED {
  IMAGE = '.jpg, .jpeg, .png',
  DOCUMENTS = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .ppt, .pptx, .mp4, .avi, .mov, .wmv, .mkv, .zip, .rar, .csv, .xml, .json',
}
