import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLink,
  EuiIcon,
  EuiPopover,
} from '@elastic/eui'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { ACTION } from '@shared/config'
import { useToggle } from '@shared/hooks'

import { useAddAttributeToGroup } from '../model'
import { list } from './styles'

export const AddAttributeToGroup: ReactFc = () => {
  const [isMenuOpen, toggleMenu] = useToggle()

  const { isLoading, data: attributes } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
    object_group__isnull: true,
  })

  const { isPending, addToGroup, isProcessed } = useAddAttributeToGroup()

  const attributesList = attributes?.results.map((attribute) => {
    return (
      <EuiContextMenuItem
        key={attribute.id}
        icon="plus"
        onClick={addToGroup(attribute.id)}
        disabled={isProcessed(attribute.id)}
      >
        {attribute.name}
      </EuiContextMenuItem>
    )
  })

  const isDisabled = attributesList && !attributesList.length

  const addToGroupButton = (
    <EuiHeaderLink
      iconType="arrowDown"
      iconSide="right"
      color="primary"
      onClick={toggleMenu}
      isLoading={isLoading || isPending}
      isDisabled={isDisabled}
    >
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem>{ACTION.ADD}</EuiFlexItem>
        <EuiFlexItem>
          <EuiIcon type="plusInCircle" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeaderLink>
  )

  return (
    <EuiPopover
      id="addToGroupPopover"
      button={addToGroupButton}
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
      anchorPosition="downCenter"
      hasArrow={false}
    >
      <EuiContextMenuPanel items={attributesList} css={list} />
    </EuiPopover>
  )
}
