import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiValidator } from '@shared/api/types'

import { useUpdateValidatorApi } from '../api'

const useUpdateValidator = (validatorId: number, onSuccessCallback?: EmptyCallback) => {
  const { mutate: updateValidator, isPending: isSubmitting } = useUpdateValidatorApi()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.VALIDATOR(validatorId) })

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleUpdateValidator = (validatorData: ApiValidator) => {
    return updateValidator(
      {
        params: {
          path: {
            id: validatorId,
          },
        },
        body: validatorData,
      },
      { onSuccess },
    )
  }

  return {
    handleUpdateValidator,
    isSubmitting,
  }
}

export { useUpdateValidator }
