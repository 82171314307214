import { EuiPageSection } from '@elastic/eui'
import { useValidatorsListApi, VALIDATORS_COLUMNS } from '@entities/validators'
import { type ApiValidator } from '@shared/api/types'
import { Table, useSortTable } from '@shared/ui/table'

export const ValidatorsTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable<ApiValidator>()
  // TODO: понадобится при реализации действий с множественным выбором
  // const { selection } = useSelectionTable<ApiValidator>()

  const { isLoading, data: validators } = useValidatorsListApi()

  const validatorsList = validators?.results || []

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={VALIDATORS_COLUMNS}
        items={sortList(validatorsList)}
        sorting={sorting}
        onChange={onTableChange}
        // TODO: понадобится при реализации действий с множественным выбором
        // selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
