import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { ERROR, FILE_UPLOAD_ALLOWED } from '@shared/config'
import { CheckboxInput, FilePicker, NumberInput, TextInput } from '@shared/ui/form'
import { type RegisterOptions } from 'react-hook-form'

import { useUploadAttributeValue } from '../model'
import { type AttributeFormFieldInputProps, type AttributeFormFieldProps } from './types'

const AttributeFormField: ReactFc<AttributeFormFieldProps> = (props) => {
  const { attribute, disablePlaceholder = false, isCompressed = false, isSubmitting } = props

  const attributeId = attribute.id.toString()

  const registerOptions: RegisterOptions = attribute.is_required
    ? {
        required: ERROR.ATTRIBUTE_EMPTY,
      }
    : {}

  const inputProps: AttributeFormFieldInputProps = {
    defaultValue: attribute.value,
    required: attribute.is_required,
    disabled: !attribute.is_editable || isSubmitting,
    compressed: isCompressed,
  }

  // Функция для загрузки файла в attribute_value
  const { filePickerRef, uploadFileToAttributeValue, isFileSubmitting } = useUploadAttributeValue()

  switch (attribute.attribute_type) {
    case API_ATTRIBUTE_TYPE.TEXT:
      return (
        <TextInput
          name={attributeId}
          placeholder={disablePlaceholder ? '' : attribute.name}
          registerOptions={registerOptions}
          inputProps={inputProps}
        />
      )
    case API_ATTRIBUTE_TYPE.NUMBER:
      return (
        <NumberInput
          name={attributeId}
          placeholder={disablePlaceholder ? '' : attribute.name}
          registerOptions={registerOptions}
          inputProps={inputProps}
        />
      )
    case API_ATTRIBUTE_TYPE.LIST:
      return null
    case API_ATTRIBUTE_TYPE.BOOL:
      return (
        <CheckboxInput
          name={attributeId}
          isDisabled={isSubmitting}
          label={disablePlaceholder ? '' : attribute.name}
        />
      )
    case API_ATTRIBUTE_TYPE.NESTED_LIST:
      return null
    case API_ATTRIBUTE_TYPE.FILE:
      return (
        <FilePicker
          name={attributeId}
          placeholder={disablePlaceholder ? '' : attribute.name}
          inputProps={{
            ref: filePickerRef,
            accept: attribute.is_preview
              ? FILE_UPLOAD_ALLOWED.IMAGE
              : FILE_UPLOAD_ALLOWED.DOCUMENTS,
            display: isCompressed ? 'default' : 'large',
            isLoading: isFileSubmitting,
            disabled: isSubmitting || isFileSubmitting,
            onChange: (files: FileList | null) => {
              return uploadFileToAttributeValue({
                attribute,
                files,
              })
            },
          }}
        />
      )
    default:
      return null
  }
}

export { AttributeFormField }
