import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { type UseTabsArgs } from './types'

const useTabs = (...args: UseTabsArgs) => {
  const [tabs, defaultTab] = args
  const [selectedTabId, setSelectedTabId] = useState(defaultTab)
  const [, setSearchParams] = useSearchParams()

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => {
      return obj.id === selectedTabId
    })?.content
  }, [selectedTabId])

  const onSelectedTabChanged = (id: string) => {
    setSearchParams({})
    setSelectedTabId(id)
  }

  return {
    selectedTabId,
    selectedTabContent,
    onSelectedTabChanged,
  }
}

export { useTabs }
