import { type EuiBasicTableColumn } from '@elastic/eui'
import { LABEL } from '@shared/config'

import { renderCategoriesCatalogs, renderCategoriesName, renderCategoriesParent } from '../lib'
import { type Category } from '../types'

const useCategoriesColumns = (): EuiBasicTableColumn<Category>[] => {
  return [
    {
      field: 'name',
      name: LABEL.NAME,
      render: renderCategoriesName,
    },
    {
      field: 'object_group',
      name: LABEL.CATALOG,
      render: renderCategoriesCatalogs,
    },
    {
      field: 'parent',
      name: LABEL.PARENT_CATEGORY,
      render: renderCategoriesParent,
    },
    {
      field: 'attributes_count',
      name: LABEL.ATTRIBUTES_IN_CATEGORY,
    },
    {
      field: 'skus_count',
      name: LABEL.SKU_IN_CATEGORY,
    },
  ]
}

export { useCategoriesColumns }
