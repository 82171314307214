import { useUpdateAttributeFiles, useUpdateObjectAttributes } from '@entities/attributes'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { useDialog } from '@shared/hooks'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { type UpdateAttributeFormInputs } from './types'

const useUpdateAttribute = () => {
  const { id: skuIdParam } = useParams()
  const skuId = parseInt(skuIdParam!)
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<UpdateAttributeFormInputs>()

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const onSuccess = () => {
    closeModal()
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.SKU(skuId) })
  }

  const { handleUpdateAttribute, isSubmitting } = useUpdateObjectAttributes(skuId, onSuccess)

  const updateAttribute = methods.handleSubmit((data) => {
    const attributeId = parseInt(Object.keys(data)[0])
    const attributeValue = data[attributeId]
    const attributeValueId = parseInt(data.attributeValueId)

    handleUpdateAttribute(attributeId, attributeValue, attributeValueId)
  })

  // Удаляем файл
  const { updateAttributeFiles, isSubmitting: isRemoving } = useUpdateAttributeFiles()

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    updateAttribute,
    updateAttributeFiles,
    isRemoving,
    isSubmitting,
  }
}

export { useUpdateAttribute }
