import { EuiPanel } from '@elastic/eui'
import { Accordion } from '@shared/ui/accordion'

import { type InfoPageCategoryProps } from './types'

const InfoPageCategory: ReactFc<InfoPageCategoryProps> = ({ id, categoryName, children }) => {
  return (
    <EuiPanel color="transparent">
      <Accordion id={categoryName + id} buttonContent={categoryName} paddingSize="m" initialIsOpen>
        {children}
      </Accordion>
    </EuiPanel>
  )
}

export { InfoPageCategory }
