import { EuiCheckbox, EuiTextTruncate } from '@elastic/eui'
import { textLeft } from '@shared/ui/styles'

import { useCategoryFilterCheckbox } from '../model'
import { type CategoryFilterCheckboxProps } from './types'

export const CategoryFilterCheckbox: ReactFc<CategoryFilterCheckboxProps> = ({
  value,
  label,
  count,
  childNodes,
  isRoot = false,
}) => {
  const { isIndeterminate, isChecked, handleToggle } = useCategoryFilterCheckbox({
    value,
    childNodes,
    isRoot,
  })

  const labelText = count !== undefined ? `${label} (${count})` : `${label}`
  const labelEllipsis = count !== undefined ? `… (${count})` : `…`

  return (
    <EuiCheckbox
      id={label}
      label={<EuiTextTruncate text={labelText} ellipsis={labelEllipsis} css={textLeft} />}
      checked={isChecked}
      indeterminate={isIndeterminate()}
      onChange={handleToggle}
    />
  )
}
