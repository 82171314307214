import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiToolTip } from '@elastic/eui'

import { type InfoTooltipProps } from './type'

const InfoTooltip: ReactFc<InfoTooltipProps> = ({ tooltip, children, color, isShow = true }) => {
  return (
    <EuiFlexGroup gutterSize="xs" alignItems="center">
      <EuiFlexItem grow={false}>{children}</EuiFlexItem>
      {isShow && (
        <EuiFlexItem grow={false}>
          <EuiToolTip position="top" content={tooltip}>
            <EuiIcon type="iInCircle" size="m" {...(color && { color })} />
          </EuiToolTip>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export { InfoTooltip }
