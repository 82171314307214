import { MESSAGE } from '@shared/config'

import { type Category } from '../types'
import { CategoriesParent } from '../ui'

const renderCategoriesParent: RenderColumn<Category, 'parent'> = (parent) => {
  if (!parent) {
    return MESSAGE.EMPTY_VALUE
  }

  return <CategoriesParent id={parent} />
}

export { renderCategoriesParent }
