import { $api } from '@shared/api'

const useCategoryApi = (id: number) => {
  return $api.useQuery('get', '/categories/{id}/', {
    params: {
      path: {
        id,
      },
    },
  })
}

export { useCategoryApi }
