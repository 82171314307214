import {
  EuiButtonIcon,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTextTruncate,
} from '@elastic/eui'
import { ACTION, CATALOG_CARD_IMAGE_SIZE, LABEL, MESSAGE } from '@shared/config'
import { fileSizeFormatter, getLocalDate } from '@shared/lib'
import { FileImage, Image } from '@shared/ui/image'
import { fontSemiBold } from '@shared/ui/styles'

import { card, deleteButton } from './styles'
import { type FileCardProps } from './types'

const FileCard: ReactFc<FileCardProps> = (props) => {
  const {
    name,
    file,
    preview,
    created_at: createdAt,
    size_bytes: sizeBytes,
    width_and_height: resolution,
    onRemove,
    isRemoving,
  } = props

  return (
    <EuiCard
      title={false}
      titleSize="xs"
      paddingSize="s"
      textAlign="center"
      display="plain"
      hasBorder
      css={card}
    >
      {/* Изображение или иконка */}
      <a href={file}>
        {(preview && (
          <Image
            src={preview}
            width={CATALOG_CARD_IMAGE_SIZE}
            height={CATALOG_CARD_IMAGE_SIZE}
            alt={name}
          />
        )) || (
          <EuiFlexGroup justifyContent="spaceAround">
            <EuiFlexItem grow={false}>
              <FileImage width={CATALOG_CARD_IMAGE_SIZE} height={CATALOG_CARD_IMAGE_SIZE} />
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </a>
      <EuiSpacer size="l" />

      {/* Имя файла */}
      <a href={file}>
        <EuiText css={fontSemiBold} color="default">
          <EuiTextTruncate text={name || MESSAGE.EMPTY_NAME} truncation="start" />
        </EuiText>
      </a>

      {/* Дата загрузки файла */}
      <EuiSpacer size="s" />
      <EuiText textAlign="left">
        {MESSAGE.FILE_UPLOAD_CREATE_AT}: {getLocalDate(createdAt)}
      </EuiText>

      {/* Размер файла и разрешение */}
      <EuiText textAlign="left">
        {LABEL.SIZE}: {fileSizeFormatter(sizeBytes)}
      </EuiText>
      <EuiText textAlign="left">
        {resolution && (
          <>
            {LABEL.RESOLUTION}: {resolution}
          </>
        )}
      </EuiText>

      {/* Отображаем кнопку удаления, если есть соответствующий action */}
      {onRemove && (
        <EuiButtonIcon
          iconType="trash"
          color="danger"
          aria-label={ACTION.DELETE}
          css={deleteButton}
          onClick={onRemove}
          isLoading={isRemoving}
        />
      )}
    </EuiCard>
  )
}

export { FileCard }
