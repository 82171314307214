import { type CategoryTreeItem } from '@entities/categories/types'
import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { type ApiCategory } from '@shared/api/types'
import { MESSAGE } from '@shared/config'

export const extractCategoryData = (apiData: ApiCategory[]): CategoryTreeItem[] => {
  const categoryMap = new Map<number, Mutable<CategoryTreeItem>>()

  // Инициализируем Category объекты и заполняем Map если есть имя
  apiData?.forEach((item) => {
    const name = extractAttribute(item, ATTRIBUTE_FLAGS.NAME)?.value || MESSAGE.EMPTY_NAME

    if (name) {
      const { id, skus_count, object_group, parent, attributes_count } = item

      categoryMap.set(id, {
        id,
        name,
        skus_count,
        attributes_count,
        object_group,
        parent,
        childNodes: [],
        isExpanded: false,
      })
    }
  })

  // Построение дерева категорий и заполнение childNodes
  categoryMap.forEach((category) => {
    if (category.parent) {
      const parentCategory = categoryMap.get(category.parent)

      if (parentCategory && parentCategory.childNodes) {
        parentCategory.childNodes = parentCategory.childNodes.concat(category.id)
      }

      if (parentCategory && parentCategory.skus_count) {
        parentCategory.skus_count += category.skus_count || 0
      }
    }
  })

  // Функция для рекурсивного сбора всех childNodes
  const collectAllChildNodes = (categoryId: number, visited = new Set<number>()): Set<number> => {
    const category = categoryMap.get(categoryId)
    if (!category) return visited

    if (category.childNodes) {
      category.childNodes.forEach((childId) => {
        if (childId && !visited.has(childId)) {
          visited.add(childId)
          collectAllChildNodes(childId, visited)
        }
      })
    }

    return visited
  }

  // Обновление childNodes для всех категорий
  categoryMap.forEach((category) => {
    if (category.id) {
      category.childNodes = Array.from(collectAllChildNodes(category.id))
    }
  })

  return Array.from(categoryMap.values()) as CategoryTreeItem[]
}
