import { $api, API_QUERY_KEY, queryClient } from '@shared/api'
import { useRouterParams } from '@shared/hooks'

export const useRemoveAttributeFromCategory = () => {
  const { hasParam, getParamValues } = useRouterParams()

  const isDisabledButton = !hasParam('selected')

  const remove = $api.useMutation('patch', '/attributes/{id}/')

  const removeAttributeFromCategory = (id: number) => {
    return remove.mutate(
      {
        params: {
          path: {
            id,
          },
        },
        body: {
          category: null,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: API_QUERY_KEY.ATTRIBUTE_LIST,
          })
        },
      },
    )
  }

  const removeFromCategory = () => {
    getParamValues('selected').map((attributeIdParam) => {
      const attributeId = parseInt(attributeIdParam)

      return removeAttributeFromCategory(attributeId)
    })
  }

  const isPending = remove.isPending

  return {
    isDisabledButton,
    isPending,
    removeFromCategory,
  }
}
