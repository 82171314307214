import { EuiForm, EuiHeaderLink } from '@elastic/eui'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, SelectInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddCategory } from '../model'

const AddCategory: ReactFc = () => {
  const {
    isOpen,
    showModal,
    closeModal,
    methods,
    isCategoryOptionsLoading,
    categoryOptions,
    addCategory,
    isSubmitting,
  } = useAddCategory()

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {ACTION.ADD_CATEGORY}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={ACTION.ADD_CATEGORY}
          onClose={closeModal}
          onSubmit={addCategory}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder={LABEL.CATEGORY_NAME}
              registerOptions={{ required: MESSAGE.ENTER_NAME }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />
            <SelectInput
              name="parent"
              placeholder={MESSAGE.ENTER_CATEGORY}
              label={LABEL.PARENT_CATEGORY}
              inputProps={{
                options: categoryOptions,
                isLoading: isCategoryOptionsLoading,
              }}
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}

export { AddCategory }
