import { useUpdateUser } from '@features/user'
import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { UserHeader, UserInfo } from '@widgets/settings'

const UserPage: ReactFc = () => {
  const { isSaveButtonActive, updateUserFieldValues, isUserFieldValuesSubmitting, methods } =
    useUpdateUser()

  useMeta({ title: PAGE_TITLES.SETTINGS.USER.title })

  return (
    <Layout
      header={
        <UserHeader
          updateUserFieldValues={updateUserFieldValues}
          isUserFieldValuesSubmitting={isUserFieldValuesSubmitting}
          isSaveButtonActive={isSaveButtonActive}
        />
      }
    >
      <UserInfo methods={methods} isUserSubmitting={isUserFieldValuesSubmitting} />
    </Layout>
  )
}

export { UserPage }
