import { API_OBJECT_GROUP, useObjectGroupListApi } from '@shared/api'

export const useCatalogListApi = () => {
  const catalogs = useObjectGroupListApi({
    type: API_OBJECT_GROUP.CATEGORY,
  })

  const hasCatalogs = (catalogs.data && catalogs.data.results.length > 0) || false

  return {
    ...catalogs,
    data: catalogs.data?.results,
    hasCatalogs,
  }
}
