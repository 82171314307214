import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { useMutation } from '@tanstack/react-query'

import { updateAttribute } from '../api'

const useUpdateAttributeApi = (id: string) => {
  const mutation = useMutation({
    mutationFn: async (body: ApiAttribute) => {
      return updateAttribute({ id, body })
    },
    onError: (error) => {
      // TODO: реализовать обработку ошибки
      console.error(error)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEY.ATTRIBUTE_LIST, id],
      })
    },
  })

  return { mutation, isSubmitting: mutation.isPending }
}

export { useUpdateAttributeApi }
