import { EuiPageSection } from '@elastic/eui'
import { ATTRIBUTE_GROUP_COLUMNS } from '@entities/attributes'
import { API_ATTRIBUTE_MODEL, useAttributeListApi, type UseAttributeListApiArgs } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { PARAMS } from '@shared/config'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useParams, useSearchParams } from 'react-router-dom'

export const AttributeGroupTable: ReactFc = () => {
  const { id: groupIdParam = '' } = useParams()
  const groupId = parseInt(groupIdParam)
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttribute>()
  const { selection } = useSelectionTable<ApiAttribute>()

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const { isLoading, data: attributes } = useAttributeListApi({
    [PARAMS.MODEL]: API_ATTRIBUTE_MODEL.SKU,
    [PARAMS.OBJECT_GROUP]: [groupId],
    search,
  } as UseAttributeListApiArgs)

  const attributeList = attributes?.results || []

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={ATTRIBUTE_GROUP_COLUMNS}
        items={sortList(attributeList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
