import { ROUTES } from '@shared/config'
import { Link } from 'react-router-dom'

import { type UsersTableRow } from '../types'

const renderUserName: RenderColumn<UsersTableRow, 'name'> = (value, { id }) => {
  return <Link to={ROUTES.SETTINGS.USERS.USER.buildPath({ id })}>{value}</Link>
}

export { renderUserName }
