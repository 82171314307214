import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { ValidatorsTable } from '@widgets/settings'

const ValidatorsPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.VALIDATORS.title })

  return (
    <Layout>
      <ValidatorsTable />
    </Layout>
  )
}

export { ValidatorsPage }
