import { ATTRIBUTE_FLAGS, extractAttribute, useObjectGroupApi } from '@shared/api'

const CategoriesCatalog: ReactFc<ItemWithId> = ({ id }) => {
  const { data: catalog } = useObjectGroupApi(id)

  const name = extractAttribute(catalog, ATTRIBUTE_FLAGS.NAME)?.value

  return <>{name}</>
}

export { CategoriesCatalog }
