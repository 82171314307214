import { EuiForm, EuiFormRow, EuiPanel, EuiSpacer } from '@elastic/eui'
import { CatalogSelect, CategorySearch, getCatalogCategoryFilterTree } from '@features/catalog'
import { CategoryFilter } from '@features/categories'
import { MESSAGE } from '@shared/config'
import React from 'react'

const CatalogSidebarForm: ReactFc = () => {
  return (
    <EuiPanel hasShadow={false}>
      <EuiForm fullWidth>
        <EuiFormRow label={MESSAGE.SELECT_CATALOG}>
          <CatalogSelect />
        </EuiFormRow>
        <EuiSpacer />

        <CategorySearch />
        <EuiSpacer />

        <CategoryFilter getTreeItems={getCatalogCategoryFilterTree} />
      </EuiForm>
    </EuiPanel>
  )
}

const CatalogSidebarFormMemo = React.memo(CatalogSidebarForm) as typeof CatalogSidebarForm

export { CatalogSidebarFormMemo as CatalogSidebarForm }
