import { EuiBottomBar, EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui'
import { useUpdateValidator, useValidatorApi } from '@entities/validators'
import { type ApiValidator } from '@shared/api/types'
import { ACTION } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { ValidatorInfoRows } from './validator-info-rows'

const ValidatorInfo: ReactFc = () => {
  const { id } = useParams()
  const validatorId = parseInt(id!)
  const { data: validator, isLoading: isValidatorLoading } = useValidatorApi(validatorId)

  const { handleUpdateValidator, isSubmitting: isValidatorSubmitting } =
    useUpdateValidator(validatorId)

  const methods = useForm<ApiValidator>({ values: validator })

  const {
    formState: { isDirty },
  } = methods

  // TODO: реализовать обработку кейса, когда валидатор не получен
  if (!validator) {
    if (!isValidatorLoading) {
      console.error('Не удалось получить валидатор.')
    }

    return null
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <InfoPageCategory id={validator.id} categoryName={ACTION.ALL_FIELDS}>
          <ValidatorInfoRows id={validator.id} isSubmitting={isValidatorSubmitting} />
        </InfoPageCategory>

        {/* TODO: заменить на другую кнопку, после мержа задачи 61 */}
        {isDirty && (
          <EuiBottomBar paddingSize="s">
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButton onClick={methods.handleSubmit(handleUpdateValidator)}>
                  {ACTION.SAVE}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiBottomBar>
        )}
      </FormProvider>
    </EuiPanel>
  )
}

export { ValidatorInfo }
