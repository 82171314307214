import { API_QUERY_KEY, useQuery } from '@shared/api'

import { getAttribute } from '../api'

const useAttributeApi = (id: string) => {
  return useQuery([API_QUERY_KEY.ATTRIBUTE_LIST, id], () => {
    return getAttribute(id)
  })
}

export { useAttributeApi }
