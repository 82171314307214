import {
  EuiButton,
  EuiFlexGroup,
  EuiForm,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui'
import { useRemoveCategory } from '@entities/categories'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ACTION, ERROR, ROUTES } from '@shared/config'
import { InfoTooltip } from '@shared/ui'
import { FormError, SaveButton } from '@shared/ui/form'
import { FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useInfoCategory } from '../model'
import { CategoryInfoRows } from './category-info-rows'

const CategoryInfo: ReactFc = () => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)

  // Обновление категории
  const { methods, changed, handleSubmit, isSubmitting: isSubmittingCategory } = useInfoCategory()

  // Удаление категории
  const navigate = useNavigate()

  const onSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.CATEGORY_LIST })
    navigate(ROUTES.SETTINGS.CATEGORIZATION.path)
  }

  const {
    isDisabledButton,
    isLoading,
    isSubmitting: isRemoving,
    removeCategory,
  } = useRemoveCategory(categoryId, onSuccess)

  const isSubmitting = isSubmittingCategory || isRemoving

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <EuiFlexGroup justifyContent="flexEnd">
        <SaveButton isActive={changed.length > 0} onClick={handleSubmit} isLoading={isSubmitting} />
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <FormProvider {...methods}>
        <EuiForm component="form" onSubmit={handleSubmit}>
          <CategoryInfoRows isSubmitting={isSubmitting} />
        </EuiForm>

        <FormError />
      </FormProvider>

      <EuiSpacer />

      <InfoTooltip tooltip={ERROR.NOT_EMPTY} isShow={isDisabledButton && !isLoading}>
        <EuiButton
          onClick={removeCategory}
          color="danger"
          disabled={isDisabledButton || isSubmitting}
        >
          {ACTION.DELETE}
        </EuiButton>
      </InfoTooltip>
    </EuiPanel>
  )
}

export { CategoryInfo }
