import { getLocalDate } from '@shared/lib'

const getDateColumnRender = <T, K extends keyof T>(): RenderColumn<T, K> => {
  const render = (value: T[K], _record: T) => {
    if (typeof value !== 'string') {
      return null
    }

    return <>{getLocalDate(value)}</>
  }

  return render
}

export { getDateColumnRender }
