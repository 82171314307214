import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { UsersHeader, UsersTable } from '@widgets/settings'

const UsersPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.USERS.title })

  return (
    <Layout header={<UsersHeader />}>
      <UsersTable />
    </Layout>
  )
}

export { UsersPage }
