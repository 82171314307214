import { useAddAttributeValue } from '@entities/attributes'
import { useCategoriesListApi } from '@entities/categories'
import {
  $api,
  API_ATTRIBUTE_MODEL,
  API_QUERY_KEY,
  queryClient,
  useAttributeListApi,
} from '@shared/api'
import { type ApiAttributeValue, type ApiCategory } from '@shared/api/types'
import { ERROR } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { convertToOption } from '@shared/lib'
import { useForm } from 'react-hook-form'

import { type AddCategoryFormInputs } from './types'

export const useAddCategory = () => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<AddCategoryFormInputs>()

  // Список категорий для выпадающего списка
  const { isLoading: isCategoryOptionsLoading, data: categories } = useCategoriesListApi()
  const categoryOptions = categories?.results.map(convertToOption) || []

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const categoryAttributes = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.CATEGORY,
  })

  const categoryAttributesWithName = categoryAttributes.data?.results.find((attribute) => {
    return attribute.is_name
  })

  const onError = () => {
    methods.setError('root', {
      type: 'manual',
      message: ERROR.SERVER,
    })
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.CATEGORY_LIST })
    closeModal()
  }

  const addCategoryName = useAddAttributeValue()

  const add = $api.useMutation('post', '/categories/', { onError, onSuccess })

  const addCategory = methods.handleSubmit(async ({ name, parent }) => {
    const newCategory = await add.mutateAsync({
      body: {
        parent,
      } as ApiCategory, //TODO: Разобраться с типами на бекенде
    })

    if (categoryAttributesWithName?.id) {
      addCategoryName.mutate(
        {
          body: {
            object_id: newCategory.id,
            attribute: categoryAttributesWithName?.id,
            value: name,
          } as ApiAttributeValue, //TODO: Разобраться с типами на бекенде
        },
        { onError, onSuccess },
      )
    }
  })

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    isCategoryOptionsLoading,
    categoryOptions,
    addCategory,
    isSubmitting: add.isPending,
  }
}
