import { useUpdateUserFieldValue, useUserApi } from '@entities/users'
import { type ApiAddUser } from '@shared/api/types'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const useUpdateUser = () => {
  const { id } = useParams()
  const userId = parseInt(id!)
  const { data: user } = useUserApi(userId)

  const userFieldValues = user?.field_values || []

  const userFieldsFormValues: Record<string, string> = userFieldValues.reduce(
    (result, { id, value }) => {
      return { ...result, [id]: value }
    },
    {},
  )

  const methods = useForm<Record<string, string>>({ values: userFieldsFormValues })

  const { isSubmitting, handleAddFieldValue, handleUpdateFieldValue } =
    useUpdateUserFieldValue(userId)

  const {
    formState: { isDirty },
  } = methods

  const updateUserFieldValues = methods.handleSubmit(async (data: Record<string, string>) => {
    for (const id in data) {
      if (userFieldsFormValues[id] && data[id] !== userFieldsFormValues[id]) {
        handleUpdateFieldValue({
          userFieldValueData: { value: data[id] },
          fieldValueId: parseInt(id!),
        })
      }

      if (!userFieldsFormValues[id] && data[id]) {
        handleAddFieldValue({
          value: data[id],
          user: userId,
          user_field: parseInt(id),
        } as ApiAddUser)
      }
    }
  })

  return {
    isSaveButtonActive: isDirty,
    updateUserFieldValues,
    isUserFieldValuesSubmitting: isSubmitting,
    methods,
  }
}

export { useUpdateUser }
