import { $api } from '../openapi-client'
import { type UseObjectGroupListApiArgs } from './types'

const useObjectGroupListApi = (args: UseObjectGroupListApiArgs = {}) => {
  return $api.useQuery('get', '/object_groups/', {
    params: {
      query: {
        size: 0,
        ...args,
      },
    },
  })
}

export { useObjectGroupListApi }
