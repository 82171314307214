import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiAttribute } from '@shared/api/types'
import { LABEL } from '@shared/config'

import { renderAttributeName, renderAttributeType } from '../lib'

export const ATTRIBUTE_GROUP_COLUMNS: EuiBasicTableColumn<ApiAttribute>[] = [
  {
    field: 'name',
    name: LABEL.ATTRIBUTE_NAME,
    render: renderAttributeName,
  },
  {
    field: 'attribute_type',
    name: LABEL.ATTRIBUTE_TYPE,
    render: renderAttributeType,
  },
]
