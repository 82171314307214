import { LABEL } from '@shared/config'

import { SkuAttributesTab } from './sku-attribute-tab'
import { SkuCategoriesTab } from './sku-categories-tab'
import { SkuFilesTab } from './sku-files-tab'

const SKU_TABS_LIST = [
  {
    id: 'attributes',
    name: LABEL.ATTRIBUTES,
    content: <SkuAttributesTab />,
  },
  {
    id: 'imagesAndFiles',
    name: LABEL.IMAGES_FILES,
    content: <SkuFilesTab />,
  },
  {
    id: 'categories',
    name: LABEL.CATEGORIES,
    content: <SkuCategoriesTab />,
  },
]

export { SKU_TABS_LIST }
