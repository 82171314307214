import { useSkuListApi } from '@entities/catalog'
import { isCatalogView } from '@features/catalog'
import { CATALOG_VIEW, PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'

import { CatalogCards } from './catalog-cards'
import { CatalogTable } from './catalog-table'

export const Catalog: ReactFc = () => {
  const { params, getParamValues } = useRouterParams()

  const query = {
    ...params,
    categories: getParamValues(PARAMS.CATEGORIES).map(Number),
  }

  const { isLoading, data: skuList = [] } = useSkuListApi(query)

  if (isCatalogView(CATALOG_VIEW.CARDS)) {
    return <CatalogCards skuList={skuList} isLoading={isLoading} />
  }

  return <CatalogTable skuList={skuList} isLoading={isLoading} />
}
