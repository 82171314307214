import { EuiContextMenuItem, EuiForm } from '@elastic/eui'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, SelectInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useUpdateUpdateCategory } from '../model'

export const UpdateCategory: ReactFc<ItemWithId> = ({ id }) => {
  const {
    isOpen,
    showModal,
    closeModal,
    methods,
    categoryOptions,
    isCategoryOptionsLoading,
    updateCategory,
    isSubmitting,
  } = useUpdateUpdateCategory(id)

  return (
    <>
      <EuiContextMenuItem icon="pencil" size="s" onClick={showModal}>
        {ACTION.EDIT}
      </EuiContextMenuItem>

      <FormProvider {...methods}>
        <Modal
          title={ACTION.EDIT_CATEGORY}
          submitButtonText={ACTION.SAVE}
          onClose={closeModal}
          onSubmit={updateCategory}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form" onSubmit={updateCategory}>
            <TextInput
              name="name"
              placeholder={LABEL.CATEGORY_NAME}
              registerOptions={{ required: MESSAGE.ENTER_NAME }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />
            <SelectInput
              name="parent"
              placeholder={MESSAGE.ENTER_CATEGORY}
              label={LABEL.PARENT_CATEGORY}
              inputProps={{
                options: categoryOptions,
                isLoading: isCategoryOptionsLoading,
              }}
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}
