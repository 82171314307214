import {
  EuiButton,
  EuiButtonEmpty,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  useGeneratedHtmlId,
} from '@elastic/eui'
import { ACTION, ELEMENTS_M_WIDTH } from '@shared/config'
import { type PropsWithChildren } from 'react'

import { type ModalProps } from './types'

export const Modal: ReactFc<PropsWithChildren<ModalProps>> = ({
  children,
  isSubmitting,
  isOpen,
  onClose,
  onSubmit,
  submitButtonText = ACTION.CREATE,
  title,
}) => {
  const modalTitleId = useGeneratedHtmlId()

  if (!isOpen) {
    return null
  }

  return (
    <EuiModal aria-labelledby={modalTitleId} maxWidth={ELEMENTS_M_WIDTH} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle size="xs">{title as string}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiHorizontalRule margin="none" />
      <EuiSpacer size="m" />

      <EuiModalBody>{children}</EuiModalBody>

      <EuiSpacer size="m" />
      <EuiHorizontalRule margin="none" />

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose} size="s" disabled={isSubmitting}>
          Отмена
        </EuiButtonEmpty>
        <EuiButton onClick={onSubmit} size="s" fill isLoading={isSubmitting}>
          {submitButtonText}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}
