import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiIconTip } from '@elastic/eui'
import { PasswordComponent } from '@entities/user'
import { ACTION, ERROR, LABEL } from '@shared/config'
import { FormError, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddUser } from '../model'
import { type AddAttributeProps } from '../types'

const AddUser: ReactFc<AddAttributeProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, handleAddUser, isSubmitting } = useAddUser({ onClose })

  const usernameValidateOptions = {
    required: ERROR.ENTER_USER_NAME,
    minLength: { value: 3, message: ERROR.USER_NAME_MIN_LENGTH },
    maxLength: { value: 150, message: ERROR.USER_NAME_MAX_LENGTH },
    validate: {
      nameFormat: (value: string) => {
        return /^[a-zA-Z0-9@._+-]+$/.test(value) || ERROR.USER_NAME_FORMAT
      },
    },
  }

  const usernameTooltip = (
    <>
      • Не более 150 символов
      <br />• Только буквы, цифры и символы @/./+/-/
    </>
  )

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_USER}
        onClose={handleModalClose}
        onSubmit={handleAddUser}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <EuiFlexGroup direction="column" gutterSize="s">
            <EuiFlexItem>
              <TextInput
                name="username"
                placeholder={LABEL.USER_NAME}
                registerOptions={usernameValidateOptions}
                inputProps={{
                  disabled: isSubmitting,
                  autoComplete: 'username',
                  append: [<EuiIconTip key="username" content={usernameTooltip} />],
                }}
                isRequired
              />
            </EuiFlexItem>
            <PasswordComponent
              isSubmitting={isSubmitting}
              password={methods.getValues('password')}
            />
            <EuiFlexItem>
              <FormError />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddUser }
