import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { Protect } from '@entities/user'
import { AddAttributeGroup, RemoveAttributeGroups } from '@features/attributes'
import { PERMISSIONS } from '@shared/api'
import { ACTION } from '@shared/config'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'

export const AttributeGroupsHeader: ReactFc = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={ACTION.FIND_GROUP} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSectionItem>
        <Protect permission={PERMISSIONS.CAN_ADD_OBJECT_GROUPS}>
          <RemoveAttributeGroups />
          <AddAttributeGroup />
        </Protect>
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
