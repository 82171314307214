import { EuiPanel } from '@elastic/eui'
import { useAttributeApi } from '@entities/attributes'
import { ACTION } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { AttributeInfoRows } from './attribute-info-rows'
import { type AttributeInfoProps } from './types'

const AttributeInfo: ReactFc<AttributeInfoProps> = ({ methods, isAttributeSubmitting }) => {
  const { id: attributeId } = useParams()
  const { data: attribute, isLoading: isAttributeLoading } = useAttributeApi(attributeId!)

  // TODO: реализовать обработку кейса, когда атрибут не получен
  if (!attribute) {
    if (!isAttributeLoading) {
      console.error('Не удалось получить атрибут.')
    }

    return null
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <InfoPageCategory id={attribute.id} categoryName={ACTION.ALL_FIELDS}>
          <AttributeInfoRows id={attribute.id} isSubmitting={isAttributeSubmitting} />
        </InfoPageCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { AttributeInfo }
