import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import { useSkuApi, useSkuUpdateApi } from '@entities/catalog'
import { CategorySearch, getSkuCategoryFilterTree } from '@features/catalog'
import { CategoryFilter } from '@features/categories'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { SaveButton } from '@shared/ui/form'
import _ from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const SkuCategoriesTab: ReactFc = () => {
  const { id: skuIdParam = '' } = useParams()
  const skuId = parseInt(skuIdParam)
  const { isLoading, data: sku } = useSkuApi(skuIdParam)
  const { addParamValue, getParamValues, hasParam } = useRouterParams()
  const updateSku = useSkuUpdateApi()

  const paramsCategories = getParamValues(PARAMS.CATEGORIES).map(Number) || []

  const doUpdateSku =
    (sku?.categories && _.xor(paramsCategories, sku.categories))?.length !== 0 || false

  // После загрузки данных выделаяем категории в дереве
  useEffect(() => {
    if (!isLoading && sku && sku.categories) {
      sku?.categories?.map((categoryId) => {
        if (!hasParam(PARAMS.CATEGORIES)) {
          addParamValue(PARAMS.CATEGORIES, categoryId)
        } else {
          addParamValue(PARAMS.CATEGORIES, categoryId)
        }
      })
    }
  }, [isLoading, sku])

  // Сохраняем данные
  const handlerUpdateCategories = () => {
    if (sku?.categories && doUpdateSku && paramsCategories.length > 0) {
      updateSku.mutateAsync(
        {
          params: {
            path: {
              id: skuId,
            },
          },
          body: {
            categories: paramsCategories,
          },
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries({ queryKey: API_QUERY_KEY.SKU(skuId) })
          },
        },
      )
    }
  }

  return (
    <>
      <EuiFlexGroup gutterSize="m">
        <EuiFlexItem grow={false}>
          <CategorySearch />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup justifyContent="flexEnd">
            <SaveButton
              isActive={doUpdateSku && paramsCategories.length > 0}
              onClick={handlerUpdateCategories}
              isLoading={updateSku.isPending}
            />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <CategoryFilter
        getTreeItems={(items) => {
          return getSkuCategoryFilterTree({ items })
        }}
      />
    </>
  )
}

export { SkuCategoriesTab }
