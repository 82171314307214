import { useUserApi, useUserFieldsListApi } from '@entities/users'
import { ChangeUserPassword } from '@features/user'
import { LABEL } from '@shared/config'
import { InfoPageRow } from '@shared/ui'
import { TextInput } from '@shared/ui/form'

import { type ValidatorInfoRowsProps } from './types'

const UserInfoRows: ReactFc<ValidatorInfoRowsProps> = ({ id: userId, isSubmitting }) => {
  const { data: user, isLoading: isUserLoading } = useUserApi(userId)
  const { data: userFieldsData } = useUserFieldsListApi()

  const isDisabled = isUserLoading || isSubmitting

  const userFieldsList = userFieldsData?.results
  const userFieldValues = user?.field_values

  const userFieldsFinalData = userFieldsList?.map(({ id, name, type }) => {
    const fieldValue = userFieldValues?.find(({ user_field }) => {
      return user_field === name
    })

    return {
      name: fieldValue?.value ? fieldValue?.id.toString() : id.toString(),
      label: name,
      // В дальнейшем, в зависимости от типа, в rowsConfig будут возвращаться разные инпуты
      type,
    }
  })

  const rowsConfig = userFieldsFinalData?.map(({ name, label }) => {
    return {
      name: label,
      field: <TextInput name={name} inputProps={{ disabled: isDisabled }} />,
    }
  })

  rowsConfig?.push({
    name: LABEL.PASSWORD,
    field: <ChangeUserPassword />,
  })

  const rows = rowsConfig?.map(({ name, field }) => {
    return <InfoPageRow key={name} label={name || ''} field={field} />
  })

  return rows || []
}

export { UserInfoRows }
