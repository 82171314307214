import { type CategoryFilterTree } from '@entities/categories/types'
import { CategoryFilterCheckbox } from '@shared/ui'

import { type GetSkuCategoryFilterTreeArgs } from './types'

export const getSkuCategoryFilterTree = ({
  items,
}: GetSkuCategoryFilterTreeArgs): CategoryFilterTree[] => {
  const categoryFilterTree = items.map((item) => {
    const { id, name, childNodes, children } = item

    return {
      id: `category_${id}`,
      label: <CategoryFilterCheckbox value={id} label={name} childNodes={childNodes} />,
      isExpanded: true,
      children: children && getSkuCategoryFilterTree({ items: children }),
    }
  })

  return categoryFilterTree
}
