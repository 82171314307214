import { EuiForm } from '@elastic/eui'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, SelectInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddSku, useSkuSelectOptions } from '../model'
import { type AddSkuProps } from './types'

const AddSku: ReactFc<AddSkuProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, handleSubmit, isSubmitting } = useAddSku(onClose)

  const {
    catalogDefaultOption,
    categoryDefaultOption,
    isCatalogOptionsLoading,
    catalogOptions,
    isCategoryOptionsLoading,
    categoryOptions,
  } = useSkuSelectOptions()

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_SKU}
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <TextInput
            name="value_name"
            placeholder={LABEL.SKU_NAME}
            registerOptions={{ required: MESSAGE.ENTER_NAME }}
            inputProps={{
              isLoading: isSubmitting,
            }}
            isRequired
          />

          <TextInput
            name="value_sku"
            placeholder={LABEL.SKU_SKU}
            registerOptions={{ required: MESSAGE.ENTER_SKU }}
            inputProps={{
              isLoading: isSubmitting,
            }}
            isRequired
          />

          <SelectInput
            name="object_group_id"
            placeholder={MESSAGE.SELECT_CATALOG}
            label={LABEL.CATALOG}
            registerOptions={{ required: MESSAGE.SELECT_CATALOG }}
            inputProps={{
              defaultValue: catalogDefaultOption,
              options: catalogOptions,
              isLoading: isCatalogOptionsLoading,
            }}
            isRequired
          />

          <SelectInput
            name="category_id"
            placeholder={MESSAGE.ENTER_CATEGORY}
            label={LABEL.CATEGORY}
            registerOptions={{ required: MESSAGE.ENTER_CATEGORY }}
            inputProps={{
              defaultValue: categoryDefaultOption,
              options: categoryOptions,
              isLoading: isCategoryOptionsLoading,
            }}
            isRequired
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddSku }
