import { $api } from '@shared/api'

const useUserApi = (id: number) => {
  return $api.useQuery('get', `/users/{id}/`, {
    params: {
      path: { id },
    },
  })
}

export { useUserApi }
