import { type EuiBasicTableColumn } from '@elastic/eui'
import { LABEL } from '@shared/config'

import { renderAttributeGroupsName } from '../lib'
import { type AttributeGroup } from '../types'

export const ATTRIBUTE_GROUPS_COLUMNS: EuiBasicTableColumn<AttributeGroup>[] = [
  {
    field: 'order',
    name: LABEL.ORDER,
    width: '120px',
  },
  {
    field: 'name',
    name: LABEL.NAME,
    render: renderAttributeGroupsName,
  },
  {
    field: 'count',
    name: LABEL.ATTRIBUTES_IN_GROUP,
  },
]
