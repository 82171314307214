import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect } from 'react'

import { type UseCategoryFilterCheckboxArgs } from './types'

export const useCategoryFilterCheckbox = ({
  value,
  childNodes = [],
  isRoot = false,
}: UseCategoryFilterCheckboxArgs) => {
  const { hasParamValue, addParamValue, removeParamValue, toggleParamValue } = useRouterParams()

  // Проверяем, что все дочернии категории сейчас выделены
  const isAllchildNodesChecked = () => {
    if (childNodes.length > 0) {
      return childNodes.every((childNodesId) => {
        return hasParamValue(PARAMS.CATEGORIES, childNodesId)
      })
    }

    return false
  }

  // Проверяем, что выделены некоторые из дочерних категорий
  const isIndeterminate = () => {
    if (childNodes.length > 0) {
      return childNodes.some((childNodesId) => {
        return hasParamValue(PARAMS.CATEGORIES, childNodesId) && !isAllchildNodesChecked()
      })
    }

    return false
  }

  // Проверяем, что выделена текущая категория
  const isChecked = hasParamValue(isRoot ? PARAMS.CATEGORY_ISNULL : PARAMS.CATEGORIES, value)

  const handleToggle = () => {
    toggleParamValue(isRoot ? PARAMS.CATEGORY_ISNULL : PARAMS.CATEGORIES, value)

    // Если выделена текущая категория или частично выделена, то при клике убираем все подкатегории
    if (!isChecked && !isIndeterminate()) {
      childNodes?.forEach((value) => {
        return addParamValue(PARAMS.CATEGORIES, value)
      })
    } else {
      childNodes?.forEach((value) => {
        return removeParamValue(PARAMS.CATEGORIES, value)
      })
    }
  }

  // Защита React route от изменения состояния до того, как он сделал рендер
  useEffect(() => {
    if (isAllchildNodesChecked()) {
      addParamValue(PARAMS.CATEGORIES, value)
    }

    if (isChecked && !isAllchildNodesChecked() && childNodes.length > 0) {
      removeParamValue(PARAMS.CATEGORIES, value)
    }
  }, [childNodes, isChecked, isIndeterminate])

  return {
    isIndeterminate,
    isChecked,
    handleToggle,
  }
}
